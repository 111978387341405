<template>
  <div>
    <div class="bg-white d-flex mb-3 p-2">
      <TButton
        content="Remove empty pallets from stock"
        class="ml-auto"
        @click="clearEmptyPallets"
        icon="cil-trash"
        color="danger"
        :disabled="removeEmptyPalletLoading"
      />
    </div>
    <CCard>
      <CCardHeader class="d-flex flex-wrap align-items-center">
        <TMessage content="Pallets have not been stored" bold color="muted" />
      </CCardHeader>
      <CCardBody class="scroll-x">
        <div class="d-inline-flex">
          <TMessageNotFound v-if="!pallets.length" />
          <CCard
            v-else
            v-for="pallet in pallets"
            :key="pallet.id"
            class="mx-2 my-2"
          >
            <CCardBody>
              <SCardPallet :pallet="pallet" refLink tiny showCountBox />
            </CCardBody>
          </CCard>
          <SButtonLoadmore class="my-auto mx-2" store="warehouse.pallets" />
        </div>
      </CCardBody>
    </CCard>
    <TSpinner :loading="shelve_loading" />
    <CCard v-for="shelve in shelves" :key="shelve.id">
      <CCardHeader>
        <TMessage content="Shelve">
          <template #suffix>
            <strong class="ml-1">{{ shelve.name }}</strong>
            <i>
              (F:{{ shelve.floor }} - C:{{ shelve.column }} - R:{{
                shelve.row
              }})
            </i>
          </template>
        </TMessage>
      </CCardHeader>
      <CCardBody class="scroll-x">
        <div v-for="floor in getMaxFloor(shelve)" :key="floor" class="mb-3">
          <div
            class="d-inline-flex"
            v-for="column in getMaxColumn(shelve, floor)"
            :key="column"
          >
            <CCard
              v-for="(location, index) in getMapLocations(
                shelve,
                floor,
                column
              )"
              :key="location.id + '-' + index"
              style="width: 115px"
              class="mb-1 mr-1"
              :borderColor="getColor(location)"
            >
              <CCardHeader class="d-flex p-0 px-2" v-if="location.id">
                <i>
                  {{ shelve.name }}{{ location.row }}-{{ location.floor }}
                </i>
                <CBadge
                  color="info"
                  style="font-size: 85%"
                  class="ml-auto my-auto"
                  v-if="location.pallet"
                >
                  {{ getTotalQuantityBoxPallet(location.pallet.pivot_boxes) }}
                </CBadge>
              </CCardHeader>
              <CCardBody class="py-2 px-1" v-if="location.id">
                <TLink
                  v-if="location.pallet"
                  :content="location.pallet.id"
                  :to="lodash.getReferenceLink('assemble-to-stock.pallet', location.pallet.id)"
                  :messageOptions="{
                    noTranslate: true,
                    bold: true,
                    alignment: 'center',
                  }"
                  removable
                  @click-remove="removePalletFromLocation(location)"
                />
                <TInputText
                  v-else
                  :title="$t('Add pallet')"
                  @update:value="addPalletToLocation(location, $event)"
                />
              </CCardBody>
              <CCardFooter class="p-0" v-if="location.id && location.pallet">
                <CProgress
                  :value="getVolume(location)"
                  :max="getMaxVolume(location)"
                  size="sm"
                  style="height: 5px"
                />
              </CCardFooter>
            </CCard>
          </div>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
export default {
  created() {
    this.fetchShelves();
    // - fetch free pallets
    this.$store.dispatch("warehouse.pallets.apply-query", {
      "filter[empty_location]": "1",
      "filter_between[boxes_volume]": "0,infinity",
      include: "pivotBoxes",
    });
  },
  computed: {
    shelves() {
      return this.$store.getters["warehouse.shelves.list"];
    },
    shelve_loading() {
      return this.$store.getters["warehouse.shelves.fetching"];
    },
    pallets() {
      return this.$store.getters["warehouse.pallets.list"];
    },
    pallet_types() {
      return this.$store.getters["warehouse.pallet_types.list"];
    },
    removeEmptyPalletLoading() {
      return this.$store.getters["warehouse.clear_empty_pallets.loading"];
    },
  },
  methods: {
    getMaxFloor(shelve) {
      return this.lodash.maxBy(shelve.locations, "floor").floor;
    },
    getMaxColumn(shelve, floor) {
      return (
        this.lodash.maxBy(this.getLocations(shelve, floor), "column")?.column ||
        1
      );
    },
    getLocations(shelve, floor, column = null) {
      let locations = shelve.locations.filter((l) => l.floor === floor);
      if (column) {
        locations = locations.filter((l) => l.column === column);
      }
      return locations;
    },
    getMapLocations(shelve, floor, column) {
      const locations = this.getLocations(shelve, floor, column);
      let mapLocations = [];
      const maxRow = this.lodash.maxBy(locations, "row")?.row || 1;
      for (let row = 1; row <= maxRow; row++) {
        mapLocations.push(locations.find((l) => l.row === row) || {});
      }
      return mapLocations;
    },
    getColor(location) {
      if (!location.pallet) {
        return undefined;
      }
      if (this.getVolume(location) <= 0) {
        return "secondary";
      }
      return "primary";
    },
    getVolume(location) {
      return location.pallet?.boxes_volume || 0;
    },
    getMaxVolume(location) {
      if (!location.pallet) {
        return 0;
      }
      return this.pallet_types.find((t) => t.id === location.pallet.type_id)
        .max_volume;
    },
    fetchShelves() {
      this.$store.dispatch("warehouse.shelves.fetch");
    },
    fetchPallets() {
      this.$store.dispatch("warehouse.pallets.fetch");
    },
    removePalletFromLocation(location) {
      this.$store
        .dispatch("warehouse.pallets.update", {
          id: location.pallet.id,
          attributes: { location_id: null },
        })
        .then(() => {
          this.fetchShelves();
          this.fetchPallets();
        });
    },
    addPalletToLocation(location, pallet_id) {
      this.$store
        .dispatch("warehouse.pallets.update", {
          id: pallet_id,
          attributes: { location_id: location.id },
        })
        .then(() => {
          this.fetchShelves();
          this.fetchPallets();
        });
    },
    clearEmptyPallets() {
      this.$store.dispatch("warehouse.clear_empty_pallets.delete").then(() => {
        this.fetchShelves();
      });
    },
    getTotalQuantityBoxPallet(boxes) {
      return this.lodash.sumBy(boxes, "quantity");
    },
  },
};
</script>
